import { useQuery } from '@tanstack/react-query';
import { Button, Card, Empty, Row, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactFC from 'react-fusioncharts';
import styled from 'styled-components';
import { useCodeFrames, useSentiments } from '../../../api/Questions';
import Loading from '../../../components/Loading';
import { getSentimentsBarChart, getSentimentsDonutChart } from '../../../fusion-charts/ChartHelper';
import useParams from '../../../hooks/useParams';
import { queryType } from '../../../types';
import ManageThemesModal from '../ManageThemesModal';
import OpenEndedQuestionsSelect from '../OpenEndedQuestionsSelect';

const ChartCard = styled(Card)`
  > div {
    display: flex;
    justify-content: center;
  }
`;

function SentimentsTab({ questions, selectedQuestionId, setSelectedQuestionId }) {
  const { surveyId } = useParams();
  const openEndedQuestions = questions.data.filter((q) => q.has_themes);

  const [isManageThemesModalVisible, setManageThemesModalVisible] = useState(false);
  const [hiddenThemes, setHiddenThemes] = useState([]);
  const [barChartConfig, setBarChartConfig] = useState(undefined);

  const { data: questionThemes, isLoading: questionThemesLoading } = useQuery(
    ['codeFrames', { questionIds: selectedQuestionId, filters: { only_final: true }, surveyId }],
    useCodeFrames(),
    {
      enabled: !!selectedQuestionId,
      select: (data) => {
        if (!data.data.length) {
          return { themes: [] };
        }
        const questionThemeJson = data.data[0].theme_json;
        const nonEmptyQuestionThemes = questionThemeJson.themes
          .filter((theme) => theme.data.response_count)
          .map((theme) => {
            const children = theme.children.filter((childTheme) => childTheme.data.response_count);
            return { ...theme, children };
          });
        return { ...questionThemeJson, themes: nonEmptyQuestionThemes };
      },
      onSuccess: (data) => {
        setBarChartConfig(getSentimentsBarChart(data.themes));
      },
      cacheTime: 0,
    },
  );

  const { data: sentiments, isLoading: sentimentsLoading } = useQuery(
    ['sentiments', { questionId: selectedQuestionId, surveyId }],
    useSentiments(),
    { enabled: !!selectedQuestionId },
  );

  useEffect(() => {
    if (questionThemes) {
      // Filter out themes that are not selected for display
      const visibleThemes = questionThemes.themes
        .filter((t) => !hiddenThemes.includes(t.data.id))
        .map((t) => {
          const children = t.children.filter((c) => !hiddenThemes.includes(c.data.id));
          return { ...t, children };
        });
      const { dataSource } = getSentimentsBarChart(visibleThemes);
      setBarChartConfig((prev) => ({ ...prev, dataSource }));
    }
  }, [questionThemes, hiddenThemes]);

  const handleChangeQuestion = (questionId) => {
    // Reset the bar chart to prevent issues with populating the chart
    setBarChartConfig(undefined);
    setSelectedQuestionId(questionId);
  };

  if (questionThemesLoading || sentimentsLoading) {
    return <Loading />;
  }

  return (
    <Space direction="vertical" size="middle">
      <OpenEndedQuestionsSelect
        openEndedQuestions={openEndedQuestions}
        selectedQuestionId={selectedQuestionId}
        setSelectedQuestionId={handleChangeQuestion}
      />
      {questions.data.find((q) => q.id === selectedQuestionId)?.display_sentiment ? (
        <>
          <ChartCard>
            <ReactFC
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getSentimentsDonutChart({
                caption: openEndedQuestions.find((q) => q.id === selectedQuestionId).name,
                sentiments: sentiments.data,
              })}
            />
          </ChartCard>
          {questionThemes.themes.length > 0 && (
            <>
              <Row justify="end">
                <Button size="small" onClick={() => setManageThemesModalVisible(true)}>
                  Manage themes
                </Button>
              </Row>
              <ChartCard>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <ReactFC {...barChartConfig} />
              </ChartCard>
              <ManageThemesModal
                themes={questionThemes.themes}
                saveThemes={setHiddenThemes}
                hiddenThemes={hiddenThemes}
                isModalVisible={isManageThemesModalVisible}
                setIsModalVisible={setManageThemesModalVisible}
                codeFrameId={questions.data.find((q) => q.id === selectedQuestionId).code_frame_id}
                surveyId={surveyId}
              />
            </>
          )}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Sentiment analysis has been hidden for this question. Please use the Summary Tab to enable sentiment analysis, or contact your organization's administrator."
        />
      )}
    </Space>
  );
}

SentimentsTab.defaultProps = {
  selectedQuestionId: undefined,
};

SentimentsTab.propTypes = {
  questions: queryType.isRequired,
  selectedQuestionId: PropTypes.number,
  setSelectedQuestionId: PropTypes.func.isRequired,
};

export default SentimentsTab;
