import { Button, Card, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MatomoEvent } from '../../../../constants';
import useMatomo from '../../../../hooks/useMatomo';
import RandomInsightCardImage from './RandomInsightCardImage';

const { Paragraph } = Typography;

const StyledCard = styled(Card)`
  max-width: 365px;
`;

const StyledParagraph = styled(Paragraph)`
  height: 66px;
`;

function InsightsCard({ insight }) {
  const navigate = useNavigate();
  const { trackEvent } = useMatomo();

  const handleClick = () => {
    trackEvent(MatomoEvent.EXPLORE_INSIGHTS_CLICKS);
    navigate(`/surveys/${insight.survey_id}/insights/${insight.id}`);
  };

  return (
    <StyledCard
      hoverable
      actions={[<Button type="link">Explore Insight</Button>]}
      onClick={handleClick}
    >
      <Space direction="vertical" size="large">
        <RandomInsightCardImage insightId={insight.id} />
        <StyledParagraph ellipsis={{ rows: 3 }}>{insight.description}</StyledParagraph>
      </Space>
    </StyledCard>
  );
}

InsightsCard.propTypes = {
  insight: PropTypes.shape({
    created_at: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
    name: PropTypes.string,
    question_id: PropTypes.number,
    raw_image: PropTypes.string,
    survey_id: PropTypes.number,
  }).isRequired,
};

export default InsightsCard;
