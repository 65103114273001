import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useUpdateCftName } from '../../api/CodeFrames';
import { UserRoleType } from '../../constants';
import { questionThemeType } from '../../types';
import ThemePicker from './ThemePicker';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    overflow-x: scroll;
    max-height: calc(100vh - 250px);
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

function ManageThemesModal({
  themes,
  saveThemes,
  hiddenThemes,
  isModalVisible,
  setIsModalVisible,
  codeFrameId,
  surveyId,
}) {
  const { user } = useAuth0();
  const roles = user['https://avalancheinsights.com/roles'];
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [editedThemes, setEditedThemes] = useState({});

  // themes with temporary edited names to be displayed in the ThemePicker
  const themesWithEditedNames = useMemo(() => {
    const editedThemeIds = Object.keys(editedThemes).map((id) => parseInt(id, 10));
    return themes.map((t) => {
      const children = t.children.map((c) => {
        if (editedThemeIds.includes(c.data.id)) {
          return { ...c, data: { ...c.data, customer_theme_name: editedThemes[c.data.id] } };
        }
        return c;
      });
      if (editedThemeIds.includes(t.data.id)) {
        return {
          ...t,
          children,
          data: { ...t.data, customer_theme_name: editedThemes[t.data.id] },
        };
      }
      return { ...t, children };
    });
  }, [themes, editedThemes]);

  const { mutate: updateCftName, isLoading: updateCftNameLoading } = useMutation(
    useUpdateCftName(),
    {
      onSuccess: () => {
        // update the codeFrames query data with the new theme names
        queryClient.setQueriesData(['codeFrames', { surveyId }], (prev) => {
          const codeFrame = prev.data.find((cf) => cf.id === codeFrameId);
          const updatedThemes = codeFrame.theme_json.themes.map((t) => {
            const customerThemeName = editedThemes[t.data.id] || t.data.customer_theme_name;
            const children = t.children.map((c) => {
              return editedThemes[c.data.id]
                ? { ...c, data: { ...c.data, customer_theme_name: editedThemes[c.data.id] } }
                : c;
            });
            return { data: { ...t.data, customer_theme_name: customerThemeName }, children };
          });
          const updatedCodeFrames = prev.data.map((cf) => {
            if (cf.id === codeFrameId) {
              return {
                ...cf,
                theme_json: { ...cf.theme_json, themes: updatedThemes },
              };
            }
            return cf;
          });
          return { ...prev, data: updatedCodeFrames };
        });
        message.success('Themes saved successfully');
      },
      onError: () => {
        message.error('Something went wrong while updating themes');
      },
    },
  );

  const onFinish = (formValues) => {
    if (Object.keys(editedThemes).length) {
      updateCftName({ codeFrameId, data: { theme_data: editedThemes } });
    }
    saveThemes(formValues.themes);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setEditedThemes({});
    setIsModalVisible(false);
  };

  return (
    <StyledModal
      title="Manage themes"
      open={isModalVisible}
      okText="Save"
      onOk={() => {
        form
          .validateFields()
          .then(onFinish)
          .catch(() => {});
      }}
      onCancel={handleCancel}
      confirmLoading={updateCftNameLoading}
      destroyOnClose
    >
      <Form form={form} preserve={false}>
        <StyledFormItem name="themes" initialValue={hiddenThemes} valuePropName="deselectedThemes">
          <ThemePicker
            themes={themesWithEditedNames}
            switchTooltip="Show/Hide Theme"
            editable={
              roles.includes(UserRoleType.ADMINISTRATOR) || roles.includes(UserRoleType.EDITOR)
            }
            handleEdit={(theme, name) => {
              setEditedThemes({
                ...editedThemes,
                [theme.data.id]: name,
              });
            }}
            allowSubthemesOnly={false}
          />
        </StyledFormItem>
      </Form>
    </StyledModal>
  );
}

ManageThemesModal.defaultProps = { themes: [] };

ManageThemesModal.propTypes = {
  themes: PropTypes.arrayOf(questionThemeType),
  saveThemes: PropTypes.func.isRequired,
  hiddenThemes: PropTypes.arrayOf(PropTypes.number).isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  codeFrameId: PropTypes.number.isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default ManageThemesModal;
