import { ExclamationCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { Button, PageHeader, Tabs, Tag } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useResponseOptions } from '../../api/Questions';
import { useQuestions, useSurvey } from '../../api/Surveys';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';
import { SurveyDashboardTabs, SurveyStatus, UserRoleType } from '../../constants';
import useParams from '../../hooks/useParams';
import useSelfServeOrganization from '../../hooks/useSelfServeOrganization';
import InviteGuestModal from './InviteGuestModal';
import ResponsesTab from './ResponsesTab';
import SentimentsTab from './SentimentsTab';
import SummaryTab from './SummaryTab';
import ThemesTab from './ThemesTab';

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 24px;
  }
`;

function SurveyDashboard() {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const roles = user['https://avalancheinsights.com/roles'];
  const { isLoading: organizationLoading, selfServeEnabled } = useSelfServeOrganization();
  const { surveyId } = useParams();
  const [searchParams] = useSearchParams();
  const getResponseOptions = useResponseOptions();

  const [activeTab, setActiveTab] = useState(SurveyDashboardTabs.SUMMARY);
  const [selectedQuestionId, setSelectedQuestionId] = useState(undefined);
  const [isInviteModalVisible, setInviteModalVisible] = useState(false);

  const { data: survey, isLoading: surveyLoading } = useQuery(
    ['survey', { surveyId }],
    useSurvey(),
  );

  const { data: questions, isLoading: questionsLoading } = useQuery(
    ['surveyDashboardQuestions', { surveyId }],
    useQuestions(),
    {
      onSuccess: (data) => {
        const questionId = parseInt(searchParams.get('questionId'), 10);
        if (data.data.some((q) => q.has_themes && q.code_frame_id && q.id === questionId)) {
          setSelectedQuestionId(questionId);
          setActiveTab(SurveyDashboardTabs.THEMES);
        } else {
          setSelectedQuestionId(data.data.find((q) => q.has_themes)?.id);
        }
      },
    },
  );

  const questionResponseOptions = useQueries({
    queries: (questions?.data || [])
      .filter((q) => q.is_filterable)
      .map((q) => {
        return {
          queryKey: ['responseOptions', { questionId: q.id, surveyId }],
          queryFn: getResponseOptions,
          select: (data) => ({ ...data, questionId: q.id }),
        };
      }),
  });

  const backNavigation = () => {
    if (selfServeEnabled && roles.includes(UserRoleType.ADMINISTRATOR || UserRoleType.EDITOR)) {
      return (
        <BackButton
          onClick={() => navigate(`/surveys/${surveyId}/insights`)}
          text="Back to Insight List"
        />
      );
    }
    return <BackButton onClick={() => navigate('/')} text="Back to Survey List" />;
  };

  if (organizationLoading || surveyLoading || questionsLoading) {
    return <Loading />;
  }

  const tabData = [
    {
      key: SurveyDashboardTabs.SUMMARY,
      label: 'Summary',
      children: (
        <SummaryTab
          questions={questions}
          setActiveTab={setActiveTab}
          setSelectedOpenEndedQuestionId={setSelectedQuestionId}
        />
      ),
    },
    {
      key: SurveyDashboardTabs.THEMES,
      label: 'Themes',
      children: (
        <ThemesTab
          questions={questions}
          selectedQuestionId={selectedQuestionId}
          setSelectedQuestionId={setSelectedQuestionId}
          questionResponseOptions={questionResponseOptions}
        />
      ),
    },
    {
      key: SurveyDashboardTabs.SENTIMENTS,
      label: 'Sentiments',
      children: (
        <SentimentsTab
          questions={questions}
          selectedQuestionId={selectedQuestionId}
          setSelectedQuestionId={setSelectedQuestionId}
        />
      ),
    },
    {
      key: SurveyDashboardTabs.RESPONSES,
      label: 'Responses',
      children: (
        <ResponsesTab
          questions={questions}
          selectedQuestionId={selectedQuestionId}
          setSelectedQuestionId={setSelectedQuestionId}
          questionResponseOptions={questionResponseOptions}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        ghost={false}
        title={survey?.data.name}
        breadcrumbRender={backNavigation}
        tags={
          survey?.data.survey_status !== SurveyStatus.PUBLISHED && (
            <Tag icon={<ExclamationCircleOutlined />} color="warning">
              This survey is not yet published. This dashboard may not be the final version.
            </Tag>
          )
        }
      />
      <StyledTabs
        activeKey={activeTab}
        onChange={setActiveTab}
        items={tabData}
        tabBarExtraContent={
          roles.includes(UserRoleType.ADMINISTRATOR) && (
            <Button
              type="link"
              icon=<UserAddOutlined />
              onClick={() => setInviteModalVisible(true)}
            >
              Manage Guests
            </Button>
          )
        }
      />
      {roles.includes(UserRoleType.ADMINISTRATOR) && (
        <InviteGuestModal
          visible={isInviteModalVisible}
          closeModal={() => setInviteModalVisible(false)}
          surveyId={surveyId}
        />
      )}
    </>
  );
}

export default SurveyDashboard;
