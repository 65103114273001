import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Image, Row, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

function ImageUploader({ onImageSelect, onImageDelete, preview }) {
  const fileInput = useRef(null);

  const handleImageInput = (e) => {
    onImageSelect(e.target.files[0]);
  };

  return (
    <Space direction="vertical">
      {preview && (
        <Row gutter={24} align="middle" wrap={false}>
          <Col flex="auto">
            <Image src={preview} alt={preview} preview={false} />
          </Col>
          <MinusCircleOutlined onClick={onImageDelete} />
        </Row>
      )}
      <input type="file" onChange={handleImageInput} ref={fileInput} style={{ display: 'none' }} />
      <Button
        type="dashed"
        onClick={() => fileInput.current?.click()}
        block
        icon={<PlusOutlined />}
      >
        Upload Image
      </Button>
    </Space>
  );
}

ImageUploader.defaultProps = { preview: null };

ImageUploader.propTypes = {
  onImageSelect: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  preview: PropTypes.string,
};

export default ImageUploader;
