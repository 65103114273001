import Icon, {
  DownOutlined,
  FrownOutlined,
  MehOutlined,
  MoreOutlined,
  SmileOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons';
import '@fontsource/roboto-mono';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Dropdown, Radio, Row, Space, Switch, Tag, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import { useCodeFrameResponses } from '../../../../api/CodeFrames';
import { useUpdateSentiment } from '../../../../api/Responses';
import SearchableTable from '../../../../components/SearchableTable';
import {
  QCPhase,
  SortItems,
  THEME_ENGINE_RANDOM_SAMPLE,
  ThemeDiscoveryTabs,
  ThemeEngineQueryKey,
} from '../../../../constants';
import useParams from '../../../../hooks/useParams';
import { ReactComponent as Wand } from '../../../../resources/wand.svg';
import {
  SentimentGrayDark,
  SentimentGreenDark,
  SentimentRedDark,
  themeColors,
} from '../../../../styles';
import { queryType, questionType, suggestedThemeType } from '../../../../types';
import useUtils from '../../utils';
import AdvancedSearchCollapse from './AdvancedSearchCollapse';
import SuggestedThemesHeader from './SuggestedThemesHeader';

const StyledSpace = styled(Space)`
  padding-left: 15px;
  padding-right: 15px;
  color: rgba(0, 0, 0, 0.45);

  .ant-space-item {
    // so that the switch is correctly vertically aligned inside the space
    display: flex;
  }
`;

const StyledHighlighter = styled(Highlighter)`
  margin-right: 8px;
`;

const StyledTag = styled(Tag)`
  cursor: pointer;
`;

const StyledSuggestionTag = styled(Tag)`
  background: #ffffff;
  border: 1px dashed rgba(0, 0, 0, 0.45);
  color: rgba(0, 0, 0, 0.45);
`;

const StyledFrownOutlined = styled(FrownOutlined)`
  transform: scale(175%);
  border-color: ${SentimentRedDark};
  color: ${SentimentRedDark};
`;

const StyledSmileOutlined = styled(SmileOutlined)`
  transform: scale(175%);
  border-color: ${SentimentGreenDark};
  color: ${SentimentGreenDark};
`;

const StyledMehOutlined = styled(MehOutlined)`
  transform: scale(175%);
  border-color: ${SentimentGrayDark};
  color: ${SentimentGrayDark};
`;

const StyledDropdown = styled(Dropdown)`
  padding: 0;
`;

const sortOptions = {
  asc: {
    title: SortItems.A_TO_Z,
    icon: <SortAscendingOutlined />,
  },
  desc: {
    title: SortItems.Z_TO_A,
    icon: <SortDescendingOutlined />,
  },
};

function ResponseTab({
  themes,
  question,
  suggestedThemes,
  selectedResponseIds,
  selectedRows,
  setSelectedRows,
  activeTab,
  selectedTabThemeId,
  queryFilters,
  setQueryFilters,
  selectedSuggestedThemeId,
  setSelectedSuggestedThemeId,
  createThemeFromSuggestedTheme,
  dismissSuggestedTheme,
  handleUntagResponses,
}) {
  const { codeFrameId } = useParams();
  const { updateResponsesForEditSentiment } = useUtils();
  const [responseFilter, setResponseFilter] = useState({ key: 'all', filter: undefined });
  const [responseSortOrder, setResponseSortOrder] = useState('asc');
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(THEME_ENGINE_RANDOM_SAMPLE);
  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [advancedSearchEnabled, setAdvancedSearchEnabled] = useState(false);
  const [sentimentEnabled, setSentimentEnabled] = useState(false);

  const { data: responses, isFetching: responsesFetching } = useQuery(
    [ThemeEngineQueryKey.RESPONSES, { codeFrameId, filters: queryFilters }],
    useCodeFrameResponses(),
    {
      keepPreviousData: true,
      // uncheck responses when you fetch new ones
      onSuccess: () => setSelectedRows({}),
    },
  );
  const updateResponseSentiment = useMutation(useUpdateSentiment());

  useEffect(() => {
    setCurrentPageNumber(1);
  }, [activeTab, setCurrentPageNumber]);

  useEffect(() => {
    const filters = {
      with_theme_ids: true,
      ...(searchBoxValue !== '' && advancedSearchEnabled && { websearch: searchBoxValue }),
      ...(searchBoxValue !== '' && !advancedSearchEnabled && { q: searchBoxValue }),
      ...(question.qc_phase === QCPhase.REMAPPING_COMPLETE && { with_verified_theme_ids: true }),
    };
    if (activeTab === ThemeDiscoveryTabs.RANDOM) {
      setQueryFilters({
        ...filters,
        order: 'random',
        page_number: 1,
        page_size: THEME_ENGINE_RANDOM_SAMPLE,
      });
    } else if (activeTab === ThemeDiscoveryTabs.SUGGESTED_THEMES) {
      setQueryFilters({
        ...filters,
        page_number: currentPageNumber,
        page_size: currentPageSize,
        suggested_theme_id: selectedSuggestedThemeId,
      });
    } else if (activeTab === ThemeDiscoveryTabs.ALL) {
      setQueryFilters({
        ...filters,
        page_number: currentPageNumber,
        page_size: currentPageSize,
        order: responseSortOrder,
        ...responseFilter.filter,
      });
    } else if (activeTab === ThemeDiscoveryTabs.THEME) {
      setQueryFilters({
        ...filters,
        page_number: currentPageNumber,
        page_size: currentPageSize,
        theme_id: selectedTabThemeId,
      });
    }
  }, [
    activeTab,
    suggestedThemes,
    selectedSuggestedThemeId,
    currentPageSize,
    currentPageNumber,
    responseFilter,
    responseSortOrder,
    searchBoxValue,
    selectedTabThemeId,
    setQueryFilters,
    advancedSearchEnabled,
    question,
  ]);

  const handleUpdateSentiment = async (responseIds, sentiment) => {
    try {
      await updateResponseSentiment.mutateAsync({
        data: {
          response_ids: responseIds,
          sentiment,
        },
      });
      updateResponsesForEditSentiment({ sentiment, responseIdsToUpdate: responseIds });
      message.success('Sentiment updated');
    } catch {
      message.error('Something went wrong');
    }
  };

  const onSearch = useCallback(
    (searchItem) => {
      setCurrentPageNumber(1);
      setSearchBoxValue(searchItem);
    },
    [setSearchBoxValue, setCurrentPageNumber],
  );

  const columns = [
    {
      title: selectedResponseIds.length === responses?.data.length ? 'Clear all' : 'Select all',
      dataIndex: 'text',
      key: 'text',
      render: (value, record) => (
        <>
          <StyledHighlighter
            searchWords={[searchBoxValue]}
            autoEscape
            textToHighlight={value}
            highlightStyle={{ fontWeight: '900' }}
            highlightTag="span"
          />
          {themes.data
            .filter((t) => record.theme_ids.includes(t.id))
            .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
            .map((t) => (
              <StyledTag
                key={t.id}
                icon={
                  record.verified_theme_ids &&
                  (record.verified_theme_ids.includes(t.id) ? (
                    <UserOutlined />
                  ) : (
                    <Icon component={Wand} data-cy="icon-wand" />
                  ))
                }
                color={themeColors[t.id % themeColors.length]}
                closable
                onClose={(e) => {
                  e.preventDefault();
                  handleUntagResponses(t.id, [record.id]);
                }}
              >
                {t.name}
              </StyledTag>
            ))}
        </>
      ),
    },
    {
      key: 'suggestion',
      align: 'right',
      render: () => (
        <StyledSuggestionTag>
          pending: {suggestedThemes.find((st) => st.id === selectedSuggestedThemeId).name}
        </StyledSuggestionTag>
      ),
      // set a custom element hidden that's true if we're not on the groups tab
      hidden: activeTab !== ThemeDiscoveryTabs.SUGGESTED_THEMES,
    },
    {
      key: 'sentiment',
      width: '50px',
      render: (_, record) => {
        if (!record.sentiment) {
          return '';
        }

        let sentimentTag;
        if (record.sentiment === 'positive') {
          sentimentTag = <StyledSmileOutlined />;
        } else if (record.sentiment === 'negative') {
          sentimentTag = <StyledFrownOutlined />;
        } else if (record.sentiment === 'neutral') {
          sentimentTag = <StyledMehOutlined />;
        }

        return (
          <Dropdown
            placement="bottom"
            menu={{
              items: [
                {
                  label: <StyledSmileOutlined />,
                  key: 'positive',
                  style: { padding: '10px 12px' },
                },
                {
                  label: <StyledFrownOutlined />,
                  key: 'negative',
                  style: { padding: '10px 12px' },
                },
                {
                  label: <StyledMehOutlined />,
                  key: 'neutral',
                  style: { padding: '10px 12px' },
                },
              ],
              onClick: ({ key: sentiment }) => handleUpdateSentiment([record.id], sentiment),
              style: { display: 'flex', padding: 0 },
            }}
          >
            {sentimentTag}
          </Dropdown>
        );
      },
      // don't show sentiments if we're not on the All Responses tab
      // or if we're on the All Responses tab and the Show Sentiments toggle is off
      hidden: activeTab !== ThemeDiscoveryTabs.ALL || !sentimentEnabled,
    },
    {
      key: 'action',
      width: '50px',
      render: (_, record) => {
        const menuItems = [];
        if (
          sentimentEnabled &&
          activeTab === ThemeDiscoveryTabs.ALL &&
          selectedResponseIds.length > 1 &&
          selectedResponseIds.includes(record.id)
        ) {
          menuItems.push({
            key: 'Bulk Update Sentiment',
            label: `Bulk Update Sentiment (${selectedResponseIds.length})`,
            children: [
              {
                label: <StyledSmileOutlined />,
                key: 'positive',
                onClick: () => handleUpdateSentiment(selectedResponseIds, 'positive'),
              },
              {
                label: <StyledFrownOutlined />,
                key: 'negative',
                onClick: () => handleUpdateSentiment(selectedResponseIds, 'negative'),
              },
              {
                label: <StyledMehOutlined />,
                key: 'neutral',
                onClick: () => handleUpdateSentiment(selectedResponseIds, 'neutral'),
              },
            ],
          });
        }

        return (
          <StyledDropdown placement="bottomRight" menu={{ items: menuItems }}>
            <Button type="text">
              <MoreOutlined />
            </Button>
          </StyledDropdown>
        );
      },
      hidden:
        !sentimentEnabled ||
        activeTab !== ThemeDiscoveryTabs.ALL ||
        selectedResponseIds.length <= 1,
    },
    // filter using the custom hidden attribute
  ].filter((col) => !col.hidden);

  const numThemesMenuItems = [
    {
      label: '1',
      key: '1',
      icon: <TagOutlined />,
      onClick: () => setResponseFilter({ key: 'num_tags', filter: { theme_count: 1 } }),
    },
    {
      label: '2',
      key: '2',
      icon: <TagOutlined />,
      onClick: () => setResponseFilter({ key: 'num_tags', filter: { theme_count: 2 } }),
    },
    {
      label: '3+',
      key: '3+',
      icon: <TagOutlined />,
      onClick: () => setResponseFilter({ key: 'num_tags', filter: { theme_count_gte: 3 } }),
    },
  ];

  const handleSetFilter = ({ target: { value } }) => {
    if (value === 'all') {
      setResponseFilter({ key: 'all', filter: undefined });
    } else if (value === 'only_tagged') {
      setResponseFilter({ key: 'only_tagged', filter: { theme_count_gte: 1 } });
    } else if (value === 'only_untagged') {
      setResponseFilter({ key: 'only_untagged', filter: { theme_count: 0 } });
    } else if (value === 'num_tags') {
      setResponseFilter({ key: 'num_tags', filter: { theme_count: 1 } });
    }
  };

  const sortComponents = (
    <>
      <Radio.Group
        onChange={handleSetFilter}
        value={responseFilter.key}
        optionType="button"
        buttonStyle="solid"
      >
        <Radio.Button value="all">All</Radio.Button>
        <Radio.Button value="only_tagged">Tagged</Radio.Button>
        <Radio.Button value="only_untagged">Untagged</Radio.Button>
        <Radio.Button value="num_tags">
          <Dropdown menu={{ items: numThemesMenuItems, selectable: false }}>
            <Space>
              # Themes
              <DownOutlined />
            </Space>
          </Dropdown>
        </Radio.Button>
      </Radio.Group>
      <Dropdown
        menu={{
          items: Object.entries(sortOptions).map(([key, value]) => ({
            key,
            label: value.title,
            icon: value.icon,
            onClick: () => setResponseSortOrder(key),
          })),
        }}
      >
        <Button type="link" icon={sortOptions[responseSortOrder].icon} data-cy="active-sort">
          {`Sorted ${sortOptions[responseSortOrder].title}`}
        </Button>
      </Dropdown>
    </>
  );

  const advancedSearchToggle = (
    <StyledSpace align="center">
      Advanced Search
      <Switch onChange={setAdvancedSearchEnabled} data-cy="advanced-search-toggle" size="small" />
    </StyledSpace>
  );

  const advancedSearchComponents = {
    toggle: advancedSearchToggle,
    collapse: advancedSearchEnabled ? <AdvancedSearchCollapse /> : null,
  };

  const sentimentToggle = (
    <StyledSpace align="center">
      Show Sentiments
      <Switch
        onChange={setSentimentEnabled}
        data-cy="sentiment-toggle"
        size="small"
        checked={sentimentEnabled}
      />
    </StyledSpace>
  );

  return (
    <Space direction="vertical" size="middle">
      {activeTab === ThemeDiscoveryTabs.SUGGESTED_THEMES ? (
        <SuggestedThemesHeader
          suggestedThemes={suggestedThemes}
          selectedSuggestedThemeId={selectedSuggestedThemeId}
          setSelectedSuggestedThemeId={setSelectedSuggestedThemeId}
          selectedResponseIds={selectedResponseIds}
          createThemeFromSuggestedTheme={createThemeFromSuggestedTheme}
          dismissSuggestedTheme={dismissSuggestedTheme}
        />
      ) : (
        <Row justify="space-between">
          <Space size={4}>
            {activeTab === ThemeDiscoveryTabs.RANDOM &&
              `Showing a random sample of ${THEME_ENGINE_RANDOM_SAMPLE} responses`}
            {activeTab === ThemeDiscoveryTabs.ALL && 'Showing all responses'}
            {activeTab === ThemeDiscoveryTabs.THEME && (
              <>
                Showing responses tagged with the theme
                <b>{themes.data.find((t) => t.id === selectedTabThemeId).name}</b>
              </>
            )}
            {searchBoxValue !== '' && (
              <>
                containing the term
                <b>{searchBoxValue.toLowerCase()}</b>
              </>
            )}
          </Space>
          {activeTab === ThemeDiscoveryTabs.ALL && sentimentToggle}
        </Row>
      )}
      <SearchableTable
        baseData={responses?.data}
        columns={columns}
        searchPlaceholder="Search Responses..."
        loading={responsesFetching}
        rowSelection={{
          selectedRowKeys: selectedRows.base,
          onChange: (selectedRowKeys) => {
            setSelectedRows((prev) => {
              const copy = { ...prev };
              copy.base = selectedRowKeys;
              return copy;
            });
          },
        }}
        onSearch={onSearch}
        sortComponents={activeTab === 'all' ? sortComponents : null}
        rowKey="id"
        advancedSearchComponents={advancedSearchComponents}
        pagination={
          activeTab === ThemeDiscoveryTabs.RANDOM
            ? {
                pageSize: THEME_ENGINE_RANDOM_SAMPLE,
                defaultPageSize: THEME_ENGINE_RANDOM_SAMPLE,
                hideOnSinglePage: true,
              }
            : {
                current: currentPageNumber,
                total: responses?.response.paging.total_count,
                pageSize: currentPageSize,
                showSizeChanger: true,
                pageSizeOptions: [30, 50, 100, 250, 500],
                onChange: setCurrentPageNumber,
                onShowSizeChange: (_, pageSize) => {
                  setCurrentPageSize(pageSize);
                  setCurrentPageNumber(1);
                },
              }
        }
      />
    </Space>
  );
}

ResponseTab.defaultProps = {
  selectedTabThemeId: undefined,
  selectedSuggestedThemeId: undefined,
};

ResponseTab.propTypes = {
  themes: queryType.isRequired,
  question: questionType.isRequired,
  suggestedThemes: PropTypes.arrayOf(suggestedThemeType).isRequired,
  selectedRows: PropTypes.objectOf(PropTypes.any).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  selectedResponseIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  activeTab: PropTypes.string.isRequired,
  selectedTabThemeId: PropTypes.number,
  queryFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setQueryFilters: PropTypes.func.isRequired,
  selectedSuggestedThemeId: PropTypes.number,
  setSelectedSuggestedThemeId: PropTypes.func.isRequired,
  createThemeFromSuggestedTheme: PropTypes.func.isRequired,
  dismissSuggestedTheme: PropTypes.func.isRequired,
  handleUntagResponses: PropTypes.func.isRequired,
};

export default ResponseTab;
