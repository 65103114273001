import { useQuery } from '@tanstack/react-query';
import { Card, Col, PageHeader, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useInsights } from '../../api/Surveys';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';
import useMatomo from '../../hooks/useMatomo';
import useParams from '../../hooks/useParams';
import KeyInsightSampleImage from '../../resources/key-insights.svg';
import KeyResponseCard from './KeyResponseCard';

const InsightDescriptionText = styled.p`
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  font-size: 18px;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

function InsightsExplorer() {
  const { insightId, surveyId } = useParams();
  const { trackPageView } = useMatomo();

  const { data: insight, isLoading: insightLoading } = useQuery(
    ['insights', { surveyId }],
    useInsights(),
    {
      select: (data) => data.data.find((i) => i.id === insightId),
    },
  );

  useEffect(() => {
    trackPageView('Insight Explorer');
  }, [trackPageView]);

  const navigate = useNavigate();

  const backNavigation = () => (
    <BackButton onClick={() => navigate(`/surveys/${surveyId}`)} text="Back to Survey" />
  );

  if (insightLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader ghost={false} breadcrumbRender={backNavigation} />
      <Space direction="vertical" size={45}>
        <Space direction="vertical" size="large">
          <ImageContainer>
            <img src={KeyInsightSampleImage} width={443} height={300} alt="Key Insights" />
          </ImageContainer>
          <InsightDescriptionText>{insight.description}</InsightDescriptionText>
        </Space>
        {insight?.quotes?.length > 0 && (
          <Row gutter={[26, 26]} justify="center">
            {insight.quotes.map((quote) => (
              <Col key={quote.id} xs={24} md={8}>
                <KeyResponseCard text={quote.text} label={quote.label} />
              </Col>
            ))}
          </Row>
        )}
        {insight?.raw_image && (
          <Card>
            <ImageContainer>
              <img src={insight.image} width="100%" alt="Supporting" id="insight-image" />
            </ImageContainer>
          </Card>
        )}
      </Space>
    </>
  );
}

export default InsightsExplorer;
