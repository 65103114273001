import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Card,
  Divider,
  Empty,
  Modal,
  PageHeader,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  useDeleteInsight,
  useInsights,
  usePublishSurvey,
  useQuestions,
  useSurvey,
} from '../../api/Surveys';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';
import { QCPhase, SurveyStatus } from '../../constants';
import useMatomo from '../../hooks/useMatomo';
import useParams from '../../hooks/useParams';
import FilteringQuestionDrawer from './FilteringQuestionDrawer';
import OpenEndedQuestionPhase from './OpenEndedQuestionPhase';
import SurveyDebriefModal from './SurveyDebriefModal';

const { confirm } = Modal;
const { Title, Link: TextLink } = Typography;

const SpaceWithGutter = styled(Space)`
  // if we don't provide margin to the space items
  // the left and right edge of the cards' hover effects will be cut off
  & > .ant-space-item {
    margin-left: 6px;
    margin-right: 6px;
  }

  .ant-card-body {
    display: none;
  }
`;

const StyledCard = styled(Card)`
  .ant-card-head-title {
    margin-right: 24px;
  }
`;

function InsightList() {
  const { surveyId } = useParams();
  const { trackPageView } = useMatomo();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [selectedFilteringQuestion, setSelectedFilteringQuestion] = useState(null);
  const [selectedOpenEndedQuestion, setSelectedOpenEndedQuestion] = useState(null);
  const [refetchInterval, setRefetchInterval] = useState(false);

  const { data: insights, isLoading: insightsLoading } = useQuery(
    ['insights', { surveyId }],
    useInsights(),
  );
  const { data: questions, isLoading: questionsLoading } = useQuery(
    ['insightListQuestions', { surveyId }],
    useQuestions(),
  );
  const { data: survey, isLoading: surveyLoading } = useQuery(
    ['survey', { surveyId }],
    useSurvey(),
    {
      refetchInterval,
      onSuccess: (data) => {
        if (!refetchInterval && data.data.survey_status === SurveyStatus.PUBLISH_IN_PROGRESS) {
          setRefetchInterval(3000);
        } else if (
          refetchInterval &&
          data.data.survey_status !== SurveyStatus.PUBLISH_IN_PROGRESS
        ) {
          setRefetchInterval(false);
        }
      },
    },
  );

  const { mutate: deleteInsight, isLoading: deleteInsightLoading } = useMutation(
    useDeleteInsight(),
  );
  const { mutate: publishSurvey, isLoading: publishSurveyLoading } = useMutation(
    usePublishSurvey(),
  );

  const handleDeleteInsight = (insightId) => {
    deleteInsight(
      { insightId, surveyId },
      {
        onSuccess: () => {
          const remainingInsights = insights.data.filter((i) => i.id !== insightId);
          queryClient.setQueryData(['insights', { surveyId }], {
            ...insights,
            data: remainingInsights,
          });
          message.success('Insight successfully deleted');
        },
        onError: () => {
          message.error('Something went wrong deleting the insight');
        },
      },
    );
  };

  const handlePublishSurvey = (status) => {
    if (status === SurveyStatus.PUBLISH_IN_PROGRESS) {
      setRefetchInterval(3000);
    }
    publishSurvey(
      { surveyId, data: { survey_status: status } },
      {
        onSuccess: () => {
          queryClient.setQueryData(['survey', { surveyId }], {
            ...survey,
            data: { ...survey.data, survey_status: status },
          });
          message.success(
            status === SurveyStatus.UNPUBLISHED
              ? 'Survey unpublished'
              : 'Survey publish in progress',
          );
        },
        onError: () => {
          message.error('Something went wrong while updating survey status');
        },
      },
    );
  };

  useEffect(() => {
    trackPageView('Insight List');
  }, [trackPageView]);

  const deleteInsightModal = (insightId) => {
    confirm({
      title: 'Do you want to delete this insight?',
      onOk: () => handleDeleteInsight(insightId),
      loading: deleteInsightLoading,
    });
  };

  const columns = [
    {
      title: `Insight (${insights?.data.length})`,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 150,
      render: (record) => (
        <Space split={<Divider type="vertical" />}>
          <Link to={`/surveys/${surveyId}/insights/${record.id}/edit`}>Edit</Link>
          <TextLink onClick={() => deleteInsightModal(record.id)}>Delete</TextLink>
        </Space>
      ),
    },
  ];

  const backNavigation = () => (
    <BackButton onClick={() => navigate('/')} text="Back to Survey List" />
  );

  if (insightsLoading || questionsLoading || surveyLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader
        ghost={false}
        title={survey.data.name}
        breadcrumbRender={backNavigation}
        extra={
          questions.data.length > 0 && (
            <>
              <Button onClick={() => navigate(`/surveys/${surveyId}`)}>View Dashboard</Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate(`/surveys/${surveyId}/insights/new`)}
              >
                New Insight
              </Button>
              {survey.data.survey_status === SurveyStatus.PUBLISHED ? (
                <Button
                  onClick={() => handlePublishSurvey(SurveyStatus.UNPUBLISHED)}
                  loading={publishSurveyLoading}
                  danger
                >
                  Unpublish
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() => handlePublishSurvey(SurveyStatus.PUBLISH_IN_PROGRESS)}
                  loading={
                    publishSurveyLoading ||
                    survey.data.survey_status === SurveyStatus.PUBLISH_IN_PROGRESS
                  }
                  danger
                >
                  Publish
                </Button>
              )}
            </>
          )
        }
      />
      {questions.data.length > 0 ? (
        <>
          <SpaceWithGutter direction="vertical" size={30}>
            <Table columns={columns} dataSource={insights?.data} rowKey="id" />
            <Space direction="vertical" size="middle">
              <Title level={5}>Open-Ended Questions</Title>
              {questions.data.some((q) => q.has_themes && q.qc_phase === QCPhase.PROCESSING) && (
                <Alert
                  message="Survey Processing"
                  description="Some of your uploaded data is currently being processed. You will be able to access the survey data from this link once processing is complete. We'll also send you an email notification as soon as your data is ready. Thank you for your patience."
                  type="info"
                  showIcon
                />
              )}
              {questions.data
                .filter((question) => question.has_themes)
                .map((question) => (
                  <StyledCard
                    key={question.id}
                    title={question.text}
                    extra={<OpenEndedQuestionPhase currentPhase={question.qc_phase} />}
                    hoverable={question.qc_phase !== QCPhase.PROCESSING}
                    onClick={
                      question.qc_phase !== QCPhase.PROCESSING
                        ? () => setSelectedOpenEndedQuestion(question)
                        : null
                    }
                  />
                ))}
            </Space>
            <Space direction="vertical" size="middle">
              <Title level={5}>Filtering Questions</Title>
              {questions.data
                .filter((question) => question.is_filterable)
                .map((question) => (
                  <Card
                    hoverable
                    key={question.id}
                    title={question.text}
                    onClick={() => setSelectedFilteringQuestion(question)}
                  />
                ))}
            </Space>
          </SpaceWithGutter>
          <FilteringQuestionDrawer
            question={selectedFilteringQuestion}
            setSelectedFilteringQuestion={setSelectedFilteringQuestion}
            surveyId={surveyId}
          />
          <SurveyDebriefModal
            question={selectedOpenEndedQuestion}
            closeModal={() => setSelectedOpenEndedQuestion(null)}
            survey={survey}
          />
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Data is currently being processed. It should be ready in a few minutes. Thank you for your patience."
        />
      )}
    </>
  );
}

export default InsightList;
