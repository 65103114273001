import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Layout } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import EmailVerification from '../EmailVerification';
import Loading from '../Loading';
import NavBar from '../NavBar';

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  background: #ffffff;
  max-width: 1440px;
  margin: auto;
`;

const StyledContent = styled(Content)`
  margin: 0px 40px 25px;
  padding-bottom: 10px;

  @media only screen and (max-width: 655px) {
    margin: 0px 16px 25px;
  }

  @media only screen and (max-width: 360px) {
    margin: 0px 8px 25px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 0;
  width: 100vw;
  align-self: center;
`;

function PageLayout({ signupComplete }) {
  const { isLoading, isAuthenticated } = useAuth0();
  const context = useOutletContext();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledLayout>
      <NavBar signupComplete={signupComplete} />
      <StyledDivider />
      {isAuthenticated && signupComplete && <EmailVerification />}
      <StyledContent>
        <Outlet context={context} />
      </StyledContent>
    </StyledLayout>
  );
}

PageLayout.defaultProps = {
  signupComplete: false,
};

PageLayout.propTypes = {
  signupComplete: PropTypes.bool,
};

export default PageLayout;
