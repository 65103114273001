import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Input, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useUpdateQuestionSummary } from '../../../../api/CodeFrames';
import { MatomoEvent } from '../../../../constants';
import useMatomo from '../../../../hooks/useMatomo';
import { summaryType } from '../../../../types';

const { TextArea } = Input;

function EditQuestionSummaryModal({ visible, closeModal, summary, codeFrameId, surveyId }) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { trackEvent } = useMatomo();
  const { mutate: updateQuestionSummary, isLoading: updateQuestionSummaryLoading } = useMutation(
    useUpdateQuestionSummary(),
  );

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({ summary: summary.summary, theme_overview: summary.theme_overview });
    }
  }, [form, summary, visible]);

  const onFinish = (values) => {
    trackEvent(MatomoEvent.EDIT_QUESTION_SUMMARY);
    updateQuestionSummary(
      {
        codeFrameId,
        data: { summary: values.summary, theme_overview: values.theme_overview },
      },
      {
        onSuccess: () => {
          queryClient.setQueryData(
            ['questionSummary', { surveyId, codeFrameId }],
            (prevSummary) => ({
              ...prevSummary,
              data: {
                ...prevSummary.data,
                summary: values.summary,
                theme_overview: values.theme_overview,
              },
            }),
          );
          message.success('Question summary updated');
        },
        onError: () => {
          message.error('Something went wrong updating question summary');
        },
        onSettled: () => {
          closeModal();
        },
      },
    );
  };

  return (
    <Modal
      title="Edit question summary"
      open={visible}
      width={600}
      onOk={() => {
        form
          .validateFields()
          .then(onFinish)
          .catch(() => {});
      }}
      okText="Update"
      onCancel={closeModal}
      confirmLoading={updateQuestionSummaryLoading}
    >
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item
          name="summary"
          label="Summary"
          rules={[
            { required: true, message: 'Question summary is required' },
            { whitespace: true, message: 'Question summary cannot be blank' },
          ]}
        >
          <TextArea rows="6" />
        </Form.Item>
        <Form.Item
          name="theme_overview"
          label="Theme Overview"
          rules={[
            { required: true, message: 'Theme overview is required' },
            { whitespace: true, message: 'Theme overview cannot be blank' },
          ]}
        >
          <TextArea rows="6" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

EditQuestionSummaryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  summary: summaryType.isRequired,
  codeFrameId: PropTypes.number.isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default EditQuestionSummaryModal;
