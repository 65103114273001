import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Image1 from '../../../../resources/insight-images/image_1.svg';
import Image2 from '../../../../resources/insight-images/image_2.svg';
import Image3 from '../../../../resources/insight-images/image_3.svg';
import Image4 from '../../../../resources/insight-images/image_4.svg';
import Image5 from '../../../../resources/insight-images/image_5.svg';
import Image6 from '../../../../resources/insight-images/image_6.svg';
import Image7 from '../../../../resources/insight-images/image_7.svg';

export const insightImages = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image2,
  Image1,
  Image3,
];

const StyledImage = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  height: 245px;
  background-position: center;
  background-size: contain;
`;
function RandomInsightCardImage({ insightId }) {
  return <StyledImage backgroundImage={insightImages[insightId % 7]} />;
}

RandomInsightCardImage.propTypes = {
  insightId: PropTypes.number.isRequired,
};

export default RandomInsightCardImage;
