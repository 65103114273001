import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Select, Space, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { MatomoEvent, ThemeChartSegmentType } from '../../../constants';
import useMatomo from '../../../hooks/useMatomo';
import { queriesType, questionType } from '../../../types';
import CreateSegmentModal from '../CreateSegmentModal';
import EditSegmentModal from '../EditSegmentModal';

const { Text, Link, Title } = Typography;
const { Option } = Select;

const StyledTag = styled(Tag)`
  margin-top: 8px;
  cursor: pointer;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledText = styled(Text)`
  color: ${(props) => (props.active ? '#fafafa' : '')};
`;

function SegmentThemesCard({
  segments,
  clearAllSegments,
  removeSegment,
  toggleSegment,
  questions,
  questionResponseOptions,
  saveSegment,
  editSegmentName,
  cachedBreakdownKey,
}) {
  const { trackEvent } = useMatomo();
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [isCreateSegmentModal, setIsCreateSegmentModal] = useState(false);
  const [isEditSegmentModal, setIsEditSegmentModal] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(undefined);

  const handleSelectBreakdown = async (questionId) => {
    setIsDropdownLoading(true);
    localStorage.setItem(cachedBreakdownKey, JSON.stringify(questionId));
    // add each response option as a separate segment
    const responseOptions = questionResponseOptions.find((q) => q.data.questionId === questionId);
    const newSegments = responseOptions?.data.data.map((option) => ({
      [questionId]: [option.text],
    }));
    await saveSegment(ThemeChartSegmentType.BREAKDOWN, newSegments);
    setIsDropdownLoading(false);
  };

  const handleSaveCustomSegment = async (selectedChoices) => {
    await saveSegment(ThemeChartSegmentType.CUSTOM, [selectedChoices]);
  };

  return (
    <div>
      <Row justify="space-between" align="top">
        <Space>
          <Title level={5}>Segment themes</Title>
        </Space>
        <Space>
          <Link
            href="https://intercom.help/fathomthat/en/articles/6368747-creating-segments"
            target="_blank"
            rel="noreferrer noopener"
          >
            How to use segments
          </Link>
        </Space>
      </Row>
      <Card size="small">
        <Row gutter={8} wrap={false}>
          <Col flex="auto">
            <StyledSelect
              placeholder="Please select a closed ended or demographic question"
              value={JSON.parse(localStorage.getItem(cachedBreakdownKey)) || null}
              onChange={handleSelectBreakdown}
              loading={isDropdownLoading}
              showArrow
            >
              {questions?.map((question) => (
                <Option key={question.id} value={question.id}>
                  {question.text}
                </Option>
              ))}
            </StyledSelect>
            <Row>
              {segments?.map((segment) => (
                <StyledTag key={segment.id} color={segment.active ? '#1890FF' : ''}>
                  <Space>
                    <StyledText
                      active={segment.active ? 1 : 0}
                      onClick={() => toggleSegment(segment.id)}
                    >
                      {segment.text}
                    </StyledText>
                    <EditOutlined
                      onClick={() => {
                        setSelectedSegment(segment);
                        setIsEditSegmentModal(true);
                      }}
                    />
                  </Space>
                </StyledTag>
              ))}
            </Row>
          </Col>
          <Col flex="none">
            <Space direction="vertical">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  trackEvent(MatomoEvent.ADD_BUTTON_IN_SEGMENTS_CLICKS);
                  setIsCreateSegmentModal(true);
                }}
              >
                Custom segment
              </Button>
              <Button
                type="link"
                onClick={() => {
                  clearAllSegments();
                  localStorage.setItem(cachedBreakdownKey, JSON.stringify(''));
                }}
                danger
                disabled={segments.length === 1}
              >
                Clear all segments
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
      <EditSegmentModal
        visible={isEditSegmentModal}
        setVisible={setIsEditSegmentModal}
        segment={selectedSegment}
        editSegmentName={editSegmentName}
        removeSegment={removeSegment}
      />
      <CreateSegmentModal
        visible={isCreateSegmentModal}
        setVisible={setIsCreateSegmentModal}
        questions={questions}
        questionResponseOptions={questionResponseOptions}
        saveSegment={handleSaveCustomSegment}
      />
    </div>
  );
}

SegmentThemesCard.defaultProps = {
  cachedBreakdownKey: undefined,
};

SegmentThemesCard.propTypes = {
  segments: PropTypes.arrayOf(PropTypes.object).isRequired,
  clearAllSegments: PropTypes.func.isRequired,
  removeSegment: PropTypes.func.isRequired,
  toggleSegment: PropTypes.func.isRequired,
  saveSegment: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(questionType.isRequired).isRequired,
  questionResponseOptions: queriesType.isRequired,
  editSegmentName: PropTypes.func.isRequired,
  cachedBreakdownKey: PropTypes.string,
};

export default SegmentThemesCard;
