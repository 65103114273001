import { useQueryClient } from '@tanstack/react-query';
import { Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ThemeEngineQueryKey } from '../../../../../constants';
import { suggestedThemeType } from '../../../../../types';

function CreateSuggestedThemeModal({
  visible,
  closeModal,
  suggestedTheme,
  createThemeFromSuggestedTheme,
  codeFrameId,
}) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({ name: suggestedTheme.name });
    }
  }, [form, suggestedTheme, visible]);

  const onFinish = async (values) => {
    setLoading(true);
    const newTheme = { ...suggestedTheme, name: values.name };
    await createThemeFromSuggestedTheme(newTheme);
    await queryClient.invalidateQueries({
      queryKey: [ThemeEngineQueryKey.RESPONSES, { codeFrameId }],
    });
    closeModal();
    setLoading(false);
  };

  return (
    <Modal
      title="Tag suggestions"
      open={visible}
      onOk={() => {
        form
          .validateFields()
          .then(onFinish)
          .catch(() => {});
      }}
      okText="Tag suggestions"
      onCancel={closeModal}
      confirmLoading={isLoading}
    >
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item
          name="name"
          label="Theme name"
          rules={[
            { required: true, message: 'Theme name required' },
            { whitespace: true, message: 'Theme name cannot be blank' },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

CreateSuggestedThemeModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  suggestedTheme: suggestedThemeType.isRequired,
  createThemeFromSuggestedTheme: PropTypes.func.isRequired,
  codeFrameId: PropTypes.number.isRequired,
};

export default CreateSuggestedThemeModal;
