import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledConcernText = styled.p`
  margin-bottom: 16px;
  font-style: italic;
`;

const ResponseLabelText = styled.p`
  color: rgba(0, 0, 0, 0.45);
`;

function KeyResponseCard({ text, label }) {
  return (
    <div>
      <StyledConcernText>{text}</StyledConcernText>
      <ResponseLabelText>{label}</ResponseLabelText>
    </div>
  );
}

KeyResponseCard.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string,
};

KeyResponseCard.defaultProps = { label: null };

export default KeyResponseCard;
