import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { MatomoEvent } from '../../../constants';
import useMatomo from '../../../hooks/useMatomo';
import { queriesType, questionType } from '../../../types';
import CreateSegmentModal from '../CreateSegmentModal';
import EditSegmentModal from '../EditSegmentModal';

const { Text } = Typography;

const StyledTag = styled(Tag)`
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  color: ${(props) => (props.active ? '#fafafa' : '')};
`;

function SegmentResponsesCard({
  questions,
  questionResponseOptions,
  segments,
  toggleSegment,
  saveSegment,
  clearSegment,
  editSegmentName,
  removeSegment,
}) {
  const { trackEvent } = useMatomo();
  const [selectedSegment, setSelectedSegment] = useState(undefined);
  const [isEditSegmentModal, setIsEditSegmentModal] = useState(false);
  const [isCreateSegmentModal, setIsCreateSegmentModal] = useState(false);

  return (
    <Card
      title="Segment Responses"
      extra={
        <Button type="link" onClick={clearSegment} danger disabled={segments.length === 1}>
          Clear all segments
        </Button>
      }
    >
      <Row>
        <Col span={24}>
          <Row justify="space-between" align="center">
            Explore responses by segment. Click &ldquo;Create new segment&ldquo; to apply a
            demographic filter.
            <Button
              type="link"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                trackEvent(MatomoEvent.ADD_BUTTON_IN_SEGMENTS_CLICKS);
                setIsCreateSegmentModal(true);
              }}
            >
              Create Segment
            </Button>
          </Row>
          {segments.map((segment) => (
            <StyledTag key={segment.id} color={segment.active ? '#1890FF' : ''}>
              <Space>
                <StyledText
                  active={segment.active ? 1 : 0}
                  onClick={() => toggleSegment(segment.id)}
                >
                  {segment.text}
                </StyledText>
                <EditOutlined
                  onClick={() => {
                    setIsEditSegmentModal(true);
                    setSelectedSegment(segment);
                  }}
                />
              </Space>
            </StyledTag>
          ))}
        </Col>
        <EditSegmentModal
          visible={isEditSegmentModal}
          setVisible={setIsEditSegmentModal}
          segment={selectedSegment}
          editSegmentName={editSegmentName}
          removeSegment={removeSegment}
        />
        <CreateSegmentModal
          visible={isCreateSegmentModal}
          setVisible={setIsCreateSegmentModal}
          questions={questions}
          saveSegment={saveSegment}
          questionResponseOptions={questionResponseOptions}
        />
      </Row>
    </Card>
  );
}

SegmentResponsesCard.propTypes = {
  questions: PropTypes.arrayOf(questionType).isRequired,
  questionResponseOptions: queriesType.isRequired,
  segments: PropTypes.arrayOf(PropTypes.any).isRequired,
  toggleSegment: PropTypes.func.isRequired,
  saveSegment: PropTypes.func.isRequired,
  clearSegment: PropTypes.func.isRequired,
  editSegmentName: PropTypes.func.isRequired,
  removeSegment: PropTypes.func.isRequired,
};

export default SegmentResponsesCard;
