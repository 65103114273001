import { useQuery } from '@tanstack/react-query';
import { Collapse, PageHeader, Space, Tag, Typography } from 'antd';
import he from 'he';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactFC from 'react-fusioncharts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useThemeSummary } from '../../api/CodeFrames';
import { useQuestionResponses } from '../../api/Questions';
import { useQuestions } from '../../api/Surveys';
import { useThemes } from '../../api/Themes';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';
import SearchableTable from '../../components/SearchableTable';
import { getThemeSentimentBarChart } from '../../fusion-charts/ChartHelper';
import useParams from '../../hooks/useParams';
import { themeColors } from '../../styles';
import ThemeSummary from './ThemeSummary';

const { Text } = Typography;
const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  background: #ffffff;
  border: 1px solid #f0f0f0;

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-item {
    border: none;
  }
`;

const StyledSearchableTable = styled(SearchableTable)`
  .ant-table-tbody > tr > td {
    border: none;
  }
`;

const StyledResponseText = styled(Text)`
  margin-right: 8px;
`;

function ResponseExplorer() {
  const navigate = useNavigate();
  const { questionId, themeId, surveyId } = useParams();
  const [searchParams] = useSearchParams();

  const [searchFilters, setSearchFilters] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [positiveCount, setPositiveCount] = useState(0);
  const [negativeCount, setNegativeCount] = useState(0);
  const [neutralCount, setNeutralCount] = useState(0);

  const SEGMENTS_CACHE_KEY = `segments${questionId}`;

  const setPercentages = (responses) => {
    setPositiveCount(
      responses.reduce((acc, cur) => {
        return cur.sentiment === 'positive' ? acc + 1 : acc;
      }, 0),
    );

    setNegativeCount(
      responses.reduce((acc, cur) => {
        return cur.sentiment === 'negative' ? acc + 1 : acc;
      }, 0),
    );

    setNeutralCount(
      responses.reduce((acc, cur) => {
        return cur.sentiment === 'neutral' ? acc + 1 : acc;
      }, 0),
    );
  };

  const { data: responses, isLoading: responsesLoading } = useQuery(
    ['responsesByTheme', { questionId, filters: searchFilters, surveyId }],
    useQuestionResponses(),
    {
      enabled: !!searchFilters,
      onSuccess: (data) => setPercentages(data.data),
    },
  );

  const themeIds = useMemo(
    () => [themeId, ...(responses ? responses.data.flatMap((r) => r.theme_ids) : [])],
    [responses, themeId],
  );
  const { data: themes, isFetching: themesFetching } = useQuery(
    ['themes', { themeIds: [...new Set(themeIds)] }],
    useThemes(),
    { enabled: !!responses },
  );

  const { data: question, isLoading: questionLoading } = useQuery(
    ['questions', { surveyId, filters: { question_id: questionId } }],
    useQuestions(),
    { select: (data) => data.data[0] },
  );

  const { data: summary, isLoading: summaryLoading } = useQuery(
    ['themeSummary', { surveyId, codeFrameId: question?.code_frame_id, themeId }],
    useThemeSummary(),
    { enabled: !!question },
  );

  useEffect(() => {
    // Filter options sometimes contain special characters like `+` and `&`
    // In order to link to this page and preserve those characters, those params are encoded as `_$PLUS_` and `_$AMPERSAND_`
    const decodedUrlSearchParams = new URLSearchParams({ theme_id: themeId, with_theme_ids: true });
    searchParams.forEach((value, key) => {
      if (key !== 'segmentId') {
        decodedUrlSearchParams.append(
          key,
          value.replaceAll('_$PLUS_', '+').replaceAll('_$AMPERSAND_', '&'),
        );
      }
    });
    setSearchFilters(decodedUrlSearchParams);
  }, [searchParams, themeId]);

  // whenever page changes, scroll back to top
  useEffect(() => {
    window.scroll(0, 0);
  }, [page]);

  const afterSearch = useCallback(
    (searchTerm) => {
      setPage(1);
      const filteredData = responses.data.filter((r) =>
        r.text.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setPercentages(filteredData);
    },
    [setPage, responses?.data],
  );

  const columns = [
    {
      dataIndex: 'text',
      // do not display theme tags until we determine if we want to show them
      render: (text, response) => (
        <>
          <StyledResponseText italic>{`"${he.decode(text)}"`}</StyledResponseText>
          {false &&
            [...new Set(response.theme_ids)].map((tId) => {
              return (
                <Tag key={tId} color={themeColors[tId % themeColors.length]}>
                  {themes.data.find((t) => t.id === tId).name}
                </Tag>
              );
            })}
        </>
      ),
    },
  ];

  const handlePageChange = (newPage, newPageSize) => {
    // if changing page size, return to page 1
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
      setPage(1);
    } else {
      setPage(newPage);
    }
  };

  const backNavigation = () => (
    <BackButton
      onClick={() => navigate(`/surveys/${surveyId}?questionId=${questionId}`)}
      text="Back to Themes"
    />
  );

  if (themesFetching || responsesLoading || questionLoading || summaryLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader
        ghost={false}
        breadcrumbRender={backNavigation}
        title={`Individual responses that contain the theme ${
          themes.data.find((t) => t.id === themeId).name
        }`}
        // if these are responses for a given segment, find the text of that segment and display it
        subTitle={
          searchParams.get('segmentId') &&
          JSON.parse(localStorage.getItem(SEGMENTS_CACHE_KEY)).find(
            (s) => s.id === searchParams.get('segmentId'),
          ).text
        }
      />
      <Space direction="vertical" size="middle">
        {!searchParams.get('segmentId') && summary?.data.summary && (
          <ThemeSummary themeId={themeId} question={question} summary={summary.data} />
        )}
        {question.display_sentiment && (
          <StyledCollapse collapsible="icon">
            <Panel header={<Text strong>Sentiment Distribution</Text>}>
              <ReactFC
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getThemeSentimentBarChart({
                  positiveCount,
                  negativeCount,
                  neutralCount,
                })}
              />
            </Panel>
          </StyledCollapse>
        )}
        <StyledSearchableTable
          searchPlaceholder="Search responses..."
          showHeader={false}
          pagination={{
            defaultPageSize: 50,
            onChange: handlePageChange,
            current: page,
            pageSize,
          }}
          baseData={responses.data}
          columns={columns}
          rowKey="id"
          searchColumns={['text']}
          afterSearch={afterSearch}
        />
      </Space>
    </>
  );
}

export default ResponseExplorer;
