import { SettingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Image, Layout, PageHeader, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UserRoleType } from '../../constants';
import AuthenticationButton from './AuthenticationButton';
import NavName from './NavName';

const { Header } = Layout;

const StyledHeader = styled(Header)`
  height: 100%;
  background: #ffffff;
  padding: 0;
  margin: 0 40px;

  @media only screen and (max-width: 655px) {
    margin: 0 16px;
  }

  @media only screen and (max-width: 360px) {
    margin: 0 8px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 25px;
  padding: 0;
`;

function NavBar({ signupComplete }) {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const roles = user ? user['https://avalancheinsights.com/roles'] : [];
  const imgixUrl = process.env.REACT_APP_IMGIX_URL;

  return (
    <StyledHeader>
      <PageHeader
        ghost={false}
        tags={
          <Image
            alt="logo"
            preview={false}
            width={175}
            src={`${imgixUrl}/static/fathom-logo.svg`}
            onClick={() => navigate('/')}
            style={{ cursor: 'pointer' }}
          />
        }
        extra={
          signupComplete && (
            <Space size="middle" wrap>
              <NavName />
              {roles.includes(UserRoleType.ADMINISTRATOR) && (
                <StyledButton type="text" onClick={() => navigate('/settings')}>
                  <SettingOutlined />
                </StyledButton>
              )}
              <AuthenticationButton />
            </Space>
          )
        }
      />
    </StyledHeader>
  );
}

NavBar.propTypes = {
  signupComplete: PropTypes.bool.isRequired,
};

export default NavBar;
