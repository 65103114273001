import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Row,
  Space,
  Typography,
  message,
} from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useInsights, useUpdateInsight } from '../../api/Surveys';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';
import useParams from '../../hooks/useParams';
import ImageUploader from './ImageUploader';

const { Title } = Typography;
const { TextArea } = Input;

const StyledForm = styled(Form)`
  max-width: 600px;
`;

const StyledCol = styled(Col)`
  .ant-form-item {
    margin-bottom: unset;
  }
`;

function InsightForm() {
  const { surveyId, insightId } = useParams();
  const [form] = Form.useForm();
  // Need to keep track of both the image file and the URI of the image
  // Image URI could be either an imgix URL (for existing images)
  // Or a base-64 encoded image (for new images)
  const [insightImage, setInsightImage] = useState(null);
  const [insightImageURI, setInsightImageURI] = useState(null);
  const navigate = useNavigate();

  const { isLoading: insightLoading } = useQuery(['insightData', { surveyId }], useInsights(), {
    onSuccess: (data) => {
      // Get all the insights for the given survey
      // Then filter out the one with the corresponding insightId
      const currentInsight = data.data.find((d) => d.id === insightId);
      if (currentInsight) {
        form.setFieldsValue(currentInsight);
        setInsightImageURI(currentInsight.raw_image);
      }
    },
  });

  const { mutate: updateInsight, isLoading: isUpdating } = useMutation(useUpdateInsight());

  const onFinish = async (formValues) => {
    // Ant stores the form data in an object; submit it as multipart/form-data
    const formData = new FormData();
    Object.entries(formValues).forEach(([key, value]) => {
      if (value != null) {
        if (typeof value === 'object') {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
    });
    // Image is added separately since we're not using Ant's image uploader
    if (insightImage != null) {
      formData.append('image_file', insightImage);
    }
    updateInsight(
      { surveyId, data: formData },
      {
        onSuccess: () => {
          navigate(`/surveys/${surveyId}/insights`);
        },
        onError: () => {
          message.error('Something went wrong');
        },
      },
    );
  };

  const handleImageUpload = (imgFile) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      form.setFieldsValue({ image_changed: true });
      setInsightImage(imgFile);
      setInsightImageURI(reader.result);
    };

    reader.readAsDataURL(imgFile);
  };

  const handleImageDelete = () => {
    form.setFieldsValue({ image_changed: true });
    setInsightImage(null);
    setInsightImageURI(null);
  };

  const backNavigation = () => (
    <BackButton
      onClick={() => navigate(`/surveys/${surveyId}/insights`)}
      text="Back to Insight List"
    />
  );

  if (insightLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader
        ghost={false}
        title={insightId ? 'Edit Insight' : 'New Insight'}
        breadcrumbRender={backNavigation}
      />
      <StyledForm layout="vertical" form={form} autoComplete="off" onFinish={onFinish}>
        <Form.Item name="id" hidden="true">
          <Input />
        </Form.Item>
        <Form.Item
          label="Insight Title"
          name="name"
          rules={[{ required: true, message: 'Insight title is required.' }]}
        >
          <TextArea showCount maxLength={64} placeholder="Add a title for the insight" rows={3} />
        </Form.Item>
        <Form.Item
          label="Insight Description"
          name="description"
          rules={[{ required: true, message: 'Insight description is required.' }]}
        >
          <TextArea placeholder="Add a short description explaining the insight." rows={3} />
        </Form.Item>
        <Form.Item
          label="Order"
          name="insight_order"
          rules={[{ required: true, message: 'Order is required.' }]}
        >
          <InputNumber />
        </Form.Item>
        <Title level={5}>Supporting Responses</Title>
        <p>Add up to three survey responses or quotes which support the insight</p>
        <Form.List name="quotes">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <React.Fragment key={field.key}>
                  <Form.Item name={[field.name, 'id']} hidden="true">
                    <Input />
                  </Form.Item>
                  <Row gutter={24} align="middle" wrap={false}>
                    <Col flex="auto">
                      <Row gutter={[8, 8]}>
                        <StyledCol span={24} md={12}>
                          <Form.Item
                            label="Supporting Response"
                            name={[field.name, 'text']}
                            rules={[
                              {
                                required: true,
                                message: 'Insight quote text is required.',
                              },
                            ]}
                          >
                            <TextArea placeholder="Add text for this quote" rows={4} />
                          </Form.Item>
                        </StyledCol>
                        <Col span={24} md={12}>
                          <Form.Item
                            label="Respondent Demographic"
                            name={[field.name, 'label']}
                            rules={[
                              {
                                required: true,
                                message: 'Insight quote label is required.',
                              },
                            ]}
                          >
                            <TextArea
                              placeholder="Add a demographic label for this quote"
                              rows={4}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Row>
                </React.Fragment>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  disabled={form.getFieldValue('quotes')?.length >= 3}
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Quote
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Title level={5}>Supporting Image</Title>
          <p>Add an image or graphic to support the insight</p>
          <ImageUploader
            onImageSelect={handleImageUpload}
            onImageDelete={handleImageDelete}
            preview={insightImageURI}
          />
        </Form.Item>
        <Form.Item name="image_changed" hidden="true" initialValue={null}>
          <Input />
        </Form.Item>
        <Space align="center">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isUpdating}>
              Submit
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={() => navigate(`/surveys/${surveyId}/insights`)}>Cancel</Button>
          </Form.Item>
        </Space>
      </StyledForm>
    </>
  );
}

export default InsightForm;
